import styled from '@emotion/styled'

const Row = styled.div`
  display: flex;
  font-size: 25px;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;

  > :first-child {
    text-align: right;
  }

  > :last-child {
    text-align: left;
  }

  > svg {
    height: 250px;
  }
  > p {
    max-width: 350px;
    text-align: center;
    padding: 20px;
  }
  > p:only-child {
    max-width: 100%;
    text-align: center;
    padding: 20px;
  }

  @media (max-width: 740px) {
    flex-direction: column;
    padding-bottom: 80px;

    > :first-child,
    > :last-child {
      text-align: center;
      max-width: 100%;
    }

    .gatsby-image-wrapper,
    img {
      order: 1;
    }

    p {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
`

export default Row
