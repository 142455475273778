import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Row from '../components/row'
import Section from '../components/section'
import Selfie from '../components/images/selfie'
import AddPhoto from '../components/images/add-photos'
import SendPostcard from '../components/images/send-postcard'

const IndexPage = () => (
  <Layout>
    <SEO />
    <Hero />
    <Section id="how-it-works">
      <Row>
        <Selfie />
        <p>Travel - and take some photos and videos while you're at it!</p>
      </Row>
      <Row>
        <p>
          Use Timelense to create a postcard - customize it with pics and videos
          from your trip
        </p>
        <AddPhoto />
      </Row>
      <Row>
        <SendPostcard />
        <p>Once it's ready to send, we'll deliver your postcard for you</p>
      </Row>
      <Row>
        <p>
          To view the extra content, open Timelense to the camera screen (eg.
          Lens), scan the QR code, then hold your phone above the front of the
          postcard
        </p>
        <img src="/TLscan-Square-small.gif" alt="Scan the QR code" />
      </Row>
      <Row>
        <p>Voilà! Your postcard will come to life with photos and videos</p>
      </Row>
    </Section>
  </Layout>
)

export default IndexPage
