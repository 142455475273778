import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

export default () => {
  const data = useStaticQuery(query)
  return <Img fixed={data.file.childImageSharp.fixed} />
}

export const query = graphql`
  query {
    file(relativePath: { eq: "TLAddPhotos-Square.jpg" }) {
      childImageSharp {
        fixed(width: 350, height: 350) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
